/* src/styles.css */
a {
    color: blue;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.hoverAble {
    /*border: 2px solid #ccc;*/
    transition: transform 0.3s;
}

.hoverAble:hover {
    transform: scale(1.05);
}